


























































import HostChatMessageService from '@/services/owner/HostChatMessageService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import Vue, { VueConstructor } from 'vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

class HostChatMessageIndexDto {
  id!: string;
  guestName!: string;
  lastMessageAt!: string;
  unreadMessages!: number;
  guestId!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: {
    FormatedTimestamp,
    DataTable,
    Jumbotron,
  },
  mixins: [FormaterMixin],
  data() {
    return {
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'lastMessageAt', asc: false },
      currentPage: 1,
      loading: false,
      chatMessages: [] as HostChatMessageIndexDto[],
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostChatMessageIndex.meta.title').toString(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  methods: {
    parseDate(dateString: string): Date | null {
      return this.parseIsoDateTimeTz(dateString);
    },

    loadData() {
      this.loading = true;
      HostChatMessageService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.chatMessages = res.value.chatMessages.items;
        this.pages = res.value.chatMessages.totalPages;
      });
    },

    onRowClick(item: HostChatMessageIndexDto) {
      this.$router.push({
        name: 'HostChatMessageDetail',
        params: { guestId: item.guestId },
      });
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostChatMessageIndex.breadcrumb.last' },
      ]);
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
});
